<template>
  <div class="container wrapper">
    <div class="content">
      <BaseLoad v-if="loading" />
      <div class="con course-info-con">
        <!-- 课程详情 -->
        <div class="flex item">
          <div class="item-l">
            <img :src="preSrc + info.cover" alt="" />
          </div>
          <div class="item-r">
            <h3 class="ellipsis" :title="info.name">{{ info.name }}</h3>
            <p class="desc">{{ info.desc }}</p>
            <div class="item-r-b between">
              <div>
                <span class="price">￥{{ info.price }}</span>
                <del class="del-price">{{ info.original_price }}</del>
              </div>
              <div>
                <button class="btn" v-if="info.buy_status == 1">
                  <i class="iconfont icon-duihao"></i> 已购买
                </button>
                <button
                  class="btn"
                  v-else
                  @click="toPurchase(info.course_id, 2)"
                >
                  立即购买
                </button>
              </div>
            </div>
            <div class="flex favor-work">
              <CourseCancelFavor
                v-if="info.collect_status == 1"
                :cId="info.course_id"
                :cStatus="2"
                :cType="1"
                :fontSize="20"
                @favorCancel="handleFavorCanel"
              />
              <CourseFavor
                v-else
                :cId="info.course_id"
                :cStatus="1"
                :cType="1"
                :fontSize="20"
                @favorStatus="handleFavor"
              />
              <span class="slash">/</span>
              <WorkbenchCancel
                v-if="info.issue_status == 1"
                :cType="1"
                :cId="info.course_id"
                :cTitle="info.name"
                :cStatus="2"
                :fontSize="20"
                @workbenchCancel="workbenchCancel"
              />
              <Workbench
                v-else
                :cType="1"
                :cId="info.course_id"
                :cTitle="info.name"
                :cStatus="1"
                :fontSize="20"
                @handleWorkbench="handleWorkbench"
              />
            </div>
          </div>
        </div>
        <!-- tab -->
        <ul class="tab-con" v-if="course.length > 0">
          <li
            v-for="(item, index) in course"
            :key="item.course_id"
            :class="[currentTab == index ? 'active' : '']"
            @click="changeTab(index, item.course_id)"
          >
            <span>{{ item.name }}</span>
          </li>
        </ul>
        <div class="line" v-if="course.length > 0"></div>
        <!-- 课件列表 -->
        <ul class="list-con" v-if="course.length > 0 && list.length > 0">
          <li
            v-for="(item, index) in list"
            :key="item.course_id"
            @click="toLesson(item.course_id)"
            :style="{
              background:
                'url(' + preSrc + item.cover + ') center center no-repeat',
              backgroundSize: 'contain',
            }"
          >
            <div class="between">
              <span class="price">￥{{ item.price }}</span>
              <p class="favor">
                <CourseCancelFavor
                  v-if="item.collect_status == 1"
                  :cId="item.course_id"
                  :cStatus="2"
                  :cType="2"
                  :cIndex="index"
                  :cItem="item"
                  @favorCancel="handleFavorCanel"
                />
                <CourseFavor
                  v-else
                  :cId="item.course_id"
                  :cStatus="1"
                  :cType="2"
                  :cIndex="index"
                  :cItem="item"
                  @favorStatus="handleFavor"
                />
              </p>
            </div>
          </li>
        </ul>
        <ListLoad v-if="listLoad && !loading" />
        <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
      </div>
    </div>
    <BaseCopyright />
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      courseId: "",
      preSrc: config.src,
      info: {}, //课件详情
      list: [], //level列表
      course: [], //tab
      currentTab: 0,
      listLoad: false,
      loading: true,
    };
  },
  created() {
    let _self = this;
    _self.courseInfo = _self.$route.params.courseId;
    _self.initData(_self.courseInfo);
  },
  methods: {
    // 初始化
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.courseInfo, _self.$qs.stringify({ course_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.info = res.data.info;
            _self.course = res.data.course;
            // findIndex 可以直接目标项的索引
            _self.currentTab = _self.course.findIndex(
              (item) => item.course_id == id
            );
            if (res.data.course.length > 0) {
              _self.getLevelList(id);
            }
            _self.loading = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 获取level列表
    getLevelList(id) {
      let _self = this;
      _self.axios
        .post(config.url.levelList, _self.$qs.stringify({ course_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.list = res.data;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    changeTab(index, id) {
      let _self = this;
      if (_self.currentTab == index) {
        return;
      }
      _self.listLoad = true;
      _self.currentTab = index;
      _self.initData(id);
      _self.getLevelList(id);
    },
    // 取消收藏
    handleFavorCanel(status, index, item) {
      let _self = this;
      if (item) {
        item.collect_status = status;
        _self.$set(_self.list, index, item);
        return;
      }
      _self.info.collect_status = status;
    },
    // 收藏
    handleFavor(status, index, item) {
      let _self = this;
      if (item) {
        item.collect_status = status;
        _self.$set(_self.list, index, item);
        return;
      }
      _self.info.collect_status = status;
    },
    // 发布到工作台
    handleWorkbench(status) {
      let _self = this;
      _self.info.issue_status = status;
    },
    // 取消发布到工作台
    workbenchCancel(status) {
      let _self = this;
      _self.info.issue_status = status;
    },
    toLesson(id) {
      let _self = this;
      _self.$router.push({
        name: "level-info",
        params: {
          levelId: id,
        },
      });
    },
    toPurchase(id, type) {
      let _self = this;
      _self.$router.push({
        name: "purchase",
        params: {
          id: id,
          type: type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-con::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.tab-con li,
.list-con li {
  cursor: pointer;
}
.course-info-con {
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  padding: 60px 0;
  .item {
    background: #fff;
    box-sizing: border-box;
    margin-bottom: 60px;
    .item-l {
      width: 450px;
      height: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      overflow: hidden;
      img {
        width: 450px;
        height: 300px;
        object-fit: contain;
      }
    }
    .item-r {
      width: 750px;
      height: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px 30px 30px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 18px;
        color: $font-color-51;
        line-height: 30px;
        height: 60px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数
        -webkit-box-orient: vertical;
      }
      .item-r-b {
        background: #f9f9f9;
        border-radius: 5px 20px 20px 5px;
      }
      .price {
        font-size: 30px;
        color: $color-main;
        font-weight: bold;
        margin: 0 20px 0 30px;
      }
      .del-price {
        font-size: 18px;
        color: $font-color-9f;
      }
      .favor-work {
        color: $font-color-9f;
        margin-top: 50px;
        justify-content: flex-start;
      }
      .slash {
        margin: 0 15px;
      }
      .favor {
        margin-top: -3px;
      }
      .favor i {
        margin-right: 6px;
      }
      .icon-duihao {
        margin-right: 5px;
      }
    }
  }
  .favor i,
  .btn:hover {
    cursor: pointer;
  }
  .tab-con {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    li {
      font-size: 18px;
      color: $font-color-51;
      width: 140px;
      overflow: hidden;
      text-align: center;
      flex-shrink: 0;
      flex-grow: 0;
    }
    li::after {
      content: "";
      display: block;
      margin: 20px auto 0;
      width: 50px;
      height: 5px;
      background: #f9f9f9;
      border-radius: 5px;
    }
    li.active::after {
      content: "";
      display: block;
      margin: 20px auto 0;
      width: 50px;
      height: 5px;
      background: $color-main;
      border-radius: 5px;
    }
  }
  .line {
    width: 100%;
    height: 10px;
    background: #f9f9f9;
    margin-top: -8px;
    border-radius: 5px;
  }
  .list-con {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      width: 274px;
      height: 187px;
      margin: 30px 30px 0 0;
      border-radius: $border-radius-main;
      position: relative;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      img {
        width: 100%;
        border-radius: $border-radius-main;
      }
      div {
        position: absolute;
        bottom: 1px;
        left: 0;
        padding: 8px 20px;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        align-items: center;
        border-radius: 0 0 10px 10px;

        .favor {
          color: #fff;
        }
        .price {
          font-size: 20px;
          color: $color-main;
        }
      }
    }
    li:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>
